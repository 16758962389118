import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "../components/slider"

const MoviesPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="movies" />
      <Slider items={data.allContentfulMovies.edges} type={"movie"} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulMovies {
      edges {
        node {
          title
          movieUrl
          srcId
          image {
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default MoviesPage
